import { alpha, Box, Stack, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUiLeftVerticalNavbar } from '~/store/selectors';
import BookTypes from '~/types/app/book';
import { getBookImageUrl } from '~/utils/book';
import { mergeSx } from '~/utils/mui';
import { LeftVerticalButton } from './LeftVerticalButton';

const BookImage = ({
    book,
    imageSx,
    navbarOpen,
}: {
    book: BookTypes;
    imageSx?: SxProps<Theme>;
    navbarOpen: boolean;
}) => {
    const theme = useTheme();
    return (
        <Stack
            sx={{
                width: '19px',
                height: '19px',
                mr: navbarOpen ? 4 : 0,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: theme.radius.allLarge,
                backgroundColor: theme.palette.grey[100],
            }}
        >
            <Box
                component="img"
                onError={({ currentTarget }) => {
                    currentTarget.src = getBookImageUrl(book, true);
                }}
                sx={mergeSx(
                    {
                        height: 28,
                        borderRadius: theme.radius.all,
                    },
                    imageSx,
                )}
                src={book.imageUrl}
                alt=""
            />
        </Stack>
    );
};

export const LeftVerticalBookButtons = ({
    leftVerticalNavbarOpen,
    buttonIconSize,
    buttonPadding,
    buttonHeight,
}: {
    leftVerticalNavbarOpen: boolean;
    buttonIconSize: number;
    buttonPadding: number;
    buttonHeight: number;
}) => {
    const theme = useTheme();
    const leftVerticalNavbarState = useSelector(selectUiLeftVerticalNavbar);

    if (!Array.isArray(leftVerticalNavbarState.books) || leftVerticalNavbarState.books.length === 0) {
        return null;
    }
    return leftVerticalNavbarState.books?.map((el, index) => {
        const isFirst = index === 0;
        const isLast = leftVerticalNavbarState.books && index === leftVerticalNavbarState.books.length - 1;
        return (
            <LeftVerticalButton
                key={`vertical-navbar-last-read-${el.isbn}`}
                leftVerticalNavbarOpen={leftVerticalNavbarOpen}
                boldLabel
                startIcon={
                    leftVerticalNavbarOpen ? (
                        <Stack
                            sx={{
                                height: '100%',
                                width: `${buttonIconSize}px`,
                                alignItems: 'center',
                                justifyContent: isLast ? 'flex-start' : 'flex-end',
                            }}
                        >
                            <Box
                                sx={{
                                    height: isFirst ? '90%' : isLast ? '50%' : '100%',
                                    backgroundColor: alpha(theme.palette.common.black, 0.2),
                                    width: '2px',
                                    position: 'relative',
                                }}
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: isLast ? '20px' : '10px',
                                    width: '8px',
                                    minHeight: '8px',
                                    backgroundColor: '#CDCBCB',
                                    borderRadius: '50%',
                                }}
                            />
                        </Stack>
                    ) : (
                        <BookImage
                            book={el}
                            navbarOpen={leftVerticalNavbarOpen}
                            imageSx={{
                                boxShadow: theme.shadows[3],
                            }}
                        />
                    )
                }
                altLabelNodes={
                    <Stack sx={{ flexDirection: 'row', justifyContent: 'flex-start', maxWidth: '150px' }}>
                        <BookImage book={el} navbarOpen={leftVerticalNavbarOpen} />
                        <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            {el.title}
                        </Typography>
                    </Stack>
                }
                path={el.meta.url}
                buttonPadding={buttonPadding}
                buttonHeight={buttonHeight}
                iconSize={buttonIconSize}
                sx={{
                    borderLeft: !leftVerticalNavbarOpen ? theme.border.medium : 'none',
                    borderRight: !leftVerticalNavbarOpen ? theme.border.medium : 'none',
                    borderBottom: isLast && !leftVerticalNavbarOpen ? theme.border.medium : 'none',
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: isLast && !leftVerticalNavbarOpen ? theme.radius.allLarge : 0,
                    borderBottomRightRadius: isLast && !leftVerticalNavbarOpen ? theme.radius.allLarge : 0,
                    pb: isLast && !leftVerticalNavbarOpen ? 2 : 0,
                }}
            />
        );
    });
};
