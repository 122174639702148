import * as React from 'react';
import { Stack, Typography, ListItemText, Menu, MenuItem, alpha, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import {
    Pause as IconPause,
    RotateCcw as IconRotateCcw,
    RotateCw as IconRotateCw,
    ChevronDown as IconChevronDown,
    X as IconX,
} from 'react-feather';

import { AButton } from '@allvit/allvit-toolkit';

import { selectUiReader } from '~/store/selectors';
import IconButton from '~/components/atoms/IconButton';

const ReaderDrawer: React.FC = () => {
    const theme = useTheme();
    const readerState = useSelector(selectUiReader);

    const [voiceDropdown, setVoiceDropdown] = React.useState(false);
    const [speedDropdown, setSpeedDropdown] = React.useState(false);
    const voiceButtonRef = React.useRef(null);
    const speedButtonRef = React.useRef(null);

    let translateX = '0px';
    let translateY = '0px';

    if (readerState.drawer.mode !== '__closed__') {
        translateX = '0px';
    }

    if (readerState.drawer.mode === 'audio') {
        translateY = '-100px';
    }

    return (
        <Stack
            sx={{
                p: 6,
                position: 'fixed',
                width: '73%',
                height: '100px',
                borderRadius: theme.radius.topLeftLarge,
                borderTop: theme.border.thin,
                borderLeft: theme.border.thin,
                right: '0px',
                bottom: '-100px',
                backgroundColor: theme.palette.common.white,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                transition: 'all 0.5s',
            }}
            style={{
                transform: `translate(${translateX},${translateY})`,
            }}
        >
            <Stack
                sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <IconButton
                    variant="tertiary"
                    overrideColor={theme.palette.text.primary}
                    onClick={() => {
                        //
                    }}
                    sx={{ p: 0, mr: 6 }}
                >
                    <IconX size={24} color={theme.palette.text.primary} />
                </IconButton>
                <Stack
                    sx={{
                        pr: 6,
                        mr: 4,
                        flexDirection: 'row',
                        alignItems: 'center',
                        height: '60px',
                        borderRight: theme.border.thin,
                    }}
                >
                    <Typography sx={{ mr: 2, fontWeight: '700' }}>Original tekst</Typography>
                    <IconChevronDown size={22} color={theme.palette.text.primary} />
                </Stack>
                <Stack
                    sx={{
                        mr: 2,
                        px: 4,
                        height: '60px',
                        width: '300px',
                        backgroundColor: alpha(theme.palette.cta.main, 0.07),
                        borderRadius: theme.radius.all,
                    }}
                >
                    <Stack
                        sx={{
                            mb: '8px',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography sx={{ fontSize: 12, fontWeight: '700', color: theme.palette.cta.main }}>
                            0:34
                        </Typography>
                        <Stack
                            sx={{
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <IconButton
                                variant="transparent"
                                onClick={() => {
                                    //
                                }}
                            >
                                <IconRotateCcw size={18} color={theme.palette.cta.main} strokeWidth="2.5px" />
                            </IconButton>
                            <IconButton
                                variant="transparent"
                                onClick={() => {
                                    //
                                }}
                                sx={{ mx: 0 }}
                            >
                                <IconPause size={22} color={theme.palette.cta.main} />
                            </IconButton>
                            <IconButton
                                variant="transparent"
                                onClick={() => {
                                    //
                                }}
                            >
                                <IconRotateCw size={18} color={theme.palette.cta.main} strokeWidth="2.5px" />
                            </IconButton>
                        </Stack>
                        <Typography sx={{ fontSize: 12, fontWeight: '700', color: theme.palette.cta.main }}>
                            4:16
                        </Typography>
                    </Stack>
                    <Stack sx={{ position: 'relative' }}>
                        <Stack
                            sx={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                borderBottom: `2px solid ${theme.palette.cta.main}`,
                                opacity: '0.2',
                                width: '100%',
                            }}
                        ></Stack>
                        <Stack
                            sx={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                borderBottom: `2px solid ${theme.palette.cta.main}`,
                                width: '15%',
                            }}
                        ></Stack>
                    </Stack>
                </Stack>
                <Stack>
                    <AButton
                        ref={voiceButtonRef}
                        size="medium"
                        variant="secondary"
                        endIcon={<IconChevronDown size={22} />}
                        sx={{
                            mr: 2,
                            '&.MuiButton-sizeMedium': { height: '60px' },
                        }}
                        onClick={() => {
                            setVoiceDropdown(true);
                        }}
                    >
                        Allvit GURU
                    </AButton>
                    <Menu
                        anchorEl={voiceButtonRef.current}
                        open={voiceDropdown}
                        onClose={() => setVoiceDropdown(false)}
                        MenuListProps={{
                            'aria-labelledby': 'moreButton',
                        }}
                        sx={{
                            '.Mui-focusVisible': {
                                color: theme.palette.common.white,
                            },
                        }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem
                            component={AButton}
                            onClick={() => {
                                //
                            }}
                            aria-label="Stemme: Nora  (tekst-til-tale)"
                        >
                            <ListItemText sx={{ '.MuiTypography-root': { fontSize: 14 } }}>
                                Nora (tekst-til-tale)
                            </ListItemText>
                        </MenuItem>
                        <MenuItem
                            component={AButton}
                            onClick={() => {
                                //
                            }}
                            aria-label="Stemme: Henrik (tekst-til-tale)"
                        >
                            <ListItemText sx={{ '.MuiTypography-root': { fontSize: 14 } }}>
                                Henrik (tekst-til-tale)
                            </ListItemText>
                        </MenuItem>
                        <MenuItem
                            component={AButton}
                            onClick={() => {
                                //
                            }}
                            aria-label="Stemme: Allvit GURU"
                        >
                            <ListItemText sx={{ '.MuiTypography-root': { fontSize: 14 } }}>
                                <b>Allvit GURU (AI)</b>
                            </ListItemText>
                        </MenuItem>
                    </Menu>
                </Stack>
                <Stack>
                    <AButton
                        ref={speedButtonRef}
                        size="medium"
                        variant="secondary"
                        endIcon={<IconChevronDown size={22} />}
                        onClick={() => {
                            setSpeedDropdown(true);
                        }}
                        sx={{ '&.MuiButton-sizeMedium': { height: '60px' } }}
                    >
                        1x
                    </AButton>
                    <Menu
                        anchorEl={speedButtonRef.current}
                        open={speedDropdown}
                        onClose={() => setSpeedDropdown(false)}
                        MenuListProps={{
                            'aria-labelledby': 'moreButton',
                        }}
                        sx={{
                            '.Mui-focusVisible': {
                                color: theme.palette.common.white,
                            },
                        }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem
                            component={AButton}
                            onClick={() => {
                                //
                            }}
                            aria-label="Stemme: Nora  (tekst-til-tale)"
                        >
                            <ListItemText sx={{ '.MuiTypography-root': { fontSize: 14 } }}>0.8x</ListItemText>
                        </MenuItem>
                        <MenuItem
                            component={AButton}
                            onClick={() => {
                                //
                            }}
                            aria-label="Stemme: Henrik (tekst-til-tale)"
                        >
                            <ListItemText sx={{ '.MuiTypography-root': { fontSize: 14 } }}>
                                <b>1x</b>
                            </ListItemText>
                        </MenuItem>
                        <MenuItem
                            component={AButton}
                            onClick={() => {
                                //
                            }}
                            aria-label="Stemme: Allvit GURU"
                        >
                            <ListItemText sx={{ '.MuiTypography-root': { fontSize: 14 } }}>1.2x</ListItemText>
                        </MenuItem>
                        <MenuItem
                            component={AButton}
                            onClick={() => {
                                //
                            }}
                            aria-label="Stemme: Allvit GURU"
                        >
                            <ListItemText sx={{ '.MuiTypography-root': { fontSize: 14 } }}>1.4x</ListItemText>
                        </MenuItem>
                    </Menu>
                </Stack>
            </Stack>
            <AButton
                size="medium"
                variant="secondary"
                onClick={() => {
                    // setShowReader(true);
                }}
            >
                Vis tekst
            </AButton>
        </Stack>
    );
};
export default ReaderDrawer;
