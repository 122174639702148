import { Stack, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

import MobileMenu from '~/components/organisms/MobileMenu';
import ReaderDrawer from '~/components/organisms/ReaderDrawer';

import { selectUiLeftVerticalNavbar, selectUiMobileMenu } from '~/store/selectors';

import { mergeSx } from '~/utils/mui';

import Footer from './components/Footer';
import { Header } from './components/Header';
import { LeftVerticalNavbar } from './components/LeftVerticalNavbar';

import { useEffect, useState } from 'react';
import OutsideLayoutProps from './types';

// @TODO:
// - AccessibilitySkipButton... do we still need it or does it maybe not work correctly anyways?
//   I could not figure out how to make use of it.
const OutsideLayout = ({
    hideFooter,
    hideHeader,
    wrapContentInMain = true,
    children,
    signedIn,
    loginRedirect,
    sx,
}: OutsideLayoutProps) => {
    const theme = useTheme();
    const leftVerticalNavbarState = useSelector(selectUiLeftVerticalNavbar);
    const mobileMenuState = useSelector(selectUiMobileMenu);

    const [isMobileView, setIsMobileView] = useState<boolean>(
        () => typeof window !== 'undefined' && window.innerWidth <= theme.breakpoints.values.md,
    );

    useEffect(() => {
        const isMobileHandler = () => {
            setIsMobileView(window.innerWidth <= theme.breakpoints.values.md);
        };
        window.addEventListener('resize', isMobileHandler);
        isMobileHandler();
        return () => window.removeEventListener('resize', isMobileHandler);
    }, []);

    const content = wrapContentInMain ? (
        <main
            id="main"
            style={{
                display: 'flex',
                height: '100%',
                width: '100%',
                flexGrow: 1,
                flexDirection: 'column',
            }}
        >
            {children}
        </main>
    ) : (
        children
    );

    return (
        <>
            {/* <AccessibilitySkipButton /> */}
            <MobileMenu open={mobileMenuState.open} />
            {signedIn && !isMobileView && <LeftVerticalNavbar />}
            <Stack
                sx={mergeSx(
                    {
                        ml: signedIn && !isMobileView ? `${leftVerticalNavbarState.width}px` : '0px',
                        minHeight: '100vh',
                        backgroundColor: theme.palette.background.default,
                        pt: signedIn && !isMobileView ? 12 : 0,
                        justifyContent: 'space-between',
                    },
                    sx,
                )}
            >
                {/* Old header */}
                {(!signedIn || isMobileView) && !hideHeader && (
                    <Header signedIn={signedIn} loginRedirect={loginRedirect} />
                )}
                {content}
                {!hideFooter && <Footer />}
            </Stack>
            <ReaderDrawer />
        </>
    );
};

export default OutsideLayout;
