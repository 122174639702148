import { ButtonBase, ButtonProps, Tooltip, useTheme } from '@mui/material';
import { useLocation, useNavigate, useNavigation } from '@remix-run/react';
import { ReactNode, useEffect, useState } from 'react';

import { StableWidthLoading } from '~/components/atoms/StableWidthLoading/StableWidthLoading';
import { mergeSx } from '~/utils/mui';

interface LeftVerticalButtonProps extends ButtonProps {
    leftVerticalNavbarOpen: boolean;
    boldLabel?: boolean;
    startIcon: ReactNode;
    endIcon?: ReactNode;
    label?: string;
    altLabelNodes?: ReactNode;
    path: string;
    buttonPadding: number;
    buttonHeight: number;
    iconSize: number;
}

export const LeftVerticalButton = ({
    leftVerticalNavbarOpen,
    boldLabel,
    startIcon,
    endIcon,
    label,
    altLabelNodes,
    path,
    buttonPadding,
    buttonHeight,
    iconSize,
    sx,
}: LeftVerticalButtonProps) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const navigation = useNavigation();
    const [loadingButton, setLoadingButton] = useState<string>('');

    useEffect(() => {
        if (navigation.state === 'idle') {
            setLoadingButton('');
        }
    }, [navigation.state]);

    return (
        <Tooltip title={leftVerticalNavbarOpen ? '' : label} placement="right">
            <ButtonBase
                sx={mergeSx(
                    {
                        px: `${buttonPadding}px`,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        width: leftVerticalNavbarOpen ? '100%' : 'fit-content',
                        height: `${buttonHeight}px`,
                        borderRadius: theme.radius.all,
                        '&:hover': {
                            backgroundColor: theme.palette.background.default,
                        },
                    },
                    sx,
                )}
                onClick={() => {
                    navigate(path, { state: { from: location.pathname } });
                    setLoadingButton(path);
                }}
            >
                {startIcon}
                {leftVerticalNavbarOpen && (
                    <StableWidthLoading
                        text={label}
                        altTextNodes={altLabelNodes}
                        width="100%"
                        sxWrapper={{
                            opacity: leftVerticalNavbarOpen ? '1' : '0',
                            transition: 'all 0.5s',
                            alignItems: 'flex-start',
                            position: 'absolute',
                            top: '0',
                            left: `${buttonPadding * 2 + iconSize}px`,
                            height: `${buttonHeight}px`,
                        }}
                        sxTextWrapper={{ height: `${buttonHeight}px` }}
                        sxText={{ whiteSpace: 'nowrap', fontWeight: boldLabel ? '700' : '400' }}
                        overrideLoading={navigation.state !== 'idle' && loadingButton === path}
                    />
                )}
                {endIcon || null}
            </ButtonBase>
        </Tooltip>
    );
};
