import { alpha, Badge, ButtonBase, Stack, useTheme } from '@mui/material';
import { useNavigate } from '@remix-run/react';
import { AlignLeft, BatteryCharging, ChevronRight, Home, Search, ShoppingCart, User } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';

import themeConfig from '~/configs/theme';
import ui from '~/store/ui';

import BooksIcon from '~/components/atoms/Icon/icons/Books';
import Logo from '~/components/atoms/Logo';
import globalConfig from '~/configs/global';
import { selectUiLeftVerticalNavbar } from '~/store/selectors';
import { useCurrentUser } from '~/utils/user';
import { LeftVerticalBookButtons } from './LeftVerticalBookButtons';
import { LeftVerticalButton } from './LeftVerticalButton';

// @TODO:
// - Add night mode button
// - Fix accessibility
export const LeftVerticalNavbar = () => {
    const theme = useTheme();
    const currentUser = useCurrentUser();
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const sidebarPadding = 12;
    const buttonPadding = 12;
    const buttonIconSize = 19;
    const buttonHeight = 48;

    const leftVerticalNavbarState = useSelector(selectUiLeftVerticalNavbar);

    const cartLength = currentUser.cart.length || 0;

    return (
        <Stack
            sx={{
                position: 'fixed',
                height: '100vh',
                zIndex: 10,
                width: `${leftVerticalNavbarState.width}px`,
                backgroundColor: theme.palette.common.white,
                transition: 'width 0.5s',
            }}
        >
            <Stack
                sx={{
                    overflow: 'hidden',
                    height: '100%',
                    width: '100%',
                    justifyContent: 'space-between',
                    p: `${sidebarPadding}px`,
                }}
            >
                <Stack sx={{ alignItems: 'center' }}>
                    <Stack
                        sx={{
                            height: `${themeConfig.topRowHeight - sidebarPadding * 2}px`,
                            alignItems: leftVerticalNavbarState.open ? 'flex-start' : 'center',
                            justifyContent: 'flex-start',
                            width: '100%',
                            pt: '4px',
                        }}
                    >
                        <ButtonBase
                            sx={{
                                display: 'flex',
                                px: `${buttonPadding}px`,
                                height: `${buttonHeight}px`,
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                borderRadius: theme.radius.all,
                                '&:hover': {
                                    backgroundColor: theme.palette.background.default,
                                },
                            }}
                            onClick={() => {
                                navigate('/');
                            }}
                        >
                            {leftVerticalNavbarState.open ? (
                                <Logo
                                    sx={{
                                        transition: 'all 0.5s',
                                        left: '19px',
                                        top: '7px',
                                        opacity: leftVerticalNavbarState.open ? '1' : '0',
                                        height: 28,
                                    }}
                                />
                            ) : (
                                <Logo
                                    variant="square"
                                    sx={{
                                        height: 22,
                                    }}
                                />
                            )}
                        </ButtonBase>
                    </Stack>

                    <LeftVerticalButton
                        leftVerticalNavbarOpen={leftVerticalNavbarState.open}
                        startIcon={<Search size={buttonIconSize + 2} color={theme.palette.cta.main} />}
                        label={globalConfig.featureFlags.showAiPrototype ? 'Søk og spør' : 'Søk bøker'}
                        path={globalConfig.featureFlags.showAiPrototype ? '/sok/spor' : '/sok/boker'}
                        buttonPadding={buttonPadding}
                        buttonHeight={buttonHeight}
                        iconSize={buttonIconSize}
                        sx={{
                            boxShadow: `0px 0px 0px 2px ${alpha(theme.palette.common.black, 0.1)} inset`,
                            p: '12px',
                            height: '45px',
                            width: leftVerticalNavbarState.open ? '100%' : '45px',
                            my: 3,
                        }}
                    />

                    <LeftVerticalButton
                        leftVerticalNavbarOpen={leftVerticalNavbarState.open}
                        boldLabel
                        startIcon={<Home size={buttonIconSize} />}
                        label="Start"
                        path="/"
                        buttonPadding={buttonPadding}
                        buttonHeight={buttonHeight}
                        iconSize={buttonIconSize}
                        sx={{
                            mb: 1,
                        }}
                    />

                    <LeftVerticalButton
                        leftVerticalNavbarOpen={leftVerticalNavbarState.open}
                        boldLabel
                        startIcon={<BatteryCharging size={buttonIconSize} />}
                        label="Studiehjelpen"
                        path="/blogg"
                        buttonPadding={buttonPadding}
                        buttonHeight={buttonHeight}
                        iconSize={buttonIconSize}
                        sx={{
                            mb: 1,
                        }}
                    />

                    <LeftVerticalButton
                        leftVerticalNavbarOpen={leftVerticalNavbarState.open}
                        boldLabel
                        startIcon={<AlignLeft size={buttonIconSize} />}
                        label="Pensumlister"
                        path="/studiested"
                        buttonPadding={buttonPadding}
                        buttonHeight={buttonHeight}
                        iconSize={buttonIconSize}
                        sx={{
                            mb: 1,
                        }}
                    />

                    <LeftVerticalButton
                        leftVerticalNavbarOpen={leftVerticalNavbarState.open}
                        boldLabel
                        startIcon={<BooksIcon sx={{ fontSize: buttonIconSize }} />}
                        label="Bokhylle"
                        path="/bokhylle"
                        buttonPadding={buttonPadding}
                        buttonHeight={buttonHeight}
                        iconSize={buttonIconSize}
                        sx={{
                            border:
                                leftVerticalNavbarState.open || leftVerticalNavbarState.books.length === 0
                                    ? 'none'
                                    : theme.border.medium,
                            borderBottom: 'none',
                            borderTopRightRadius: leftVerticalNavbarState.open ? '0' : theme.radius.allLarge,
                            borderTopLeftRadius: leftVerticalNavbarState.open ? '0' : theme.radius.allLarge,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            pt: 2,
                        }}
                    />

                    <LeftVerticalBookButtons
                        leftVerticalNavbarOpen={leftVerticalNavbarState.open}
                        buttonIconSize={buttonIconSize}
                        buttonHeight={buttonHeight}
                        buttonPadding={buttonPadding}
                    />
                </Stack>
                <Stack>
                    <LeftVerticalButton
                        leftVerticalNavbarOpen={leftVerticalNavbarState.open}
                        boldLabel
                        startIcon={<ShoppingCart size={buttonIconSize} />}
                        endIcon={
                            cartLength &&
                            cartLength > 0 && (
                                <Badge
                                    sx={{
                                        fontWeight: '700',
                                        '& .MuiBadge-badge': {
                                            left: leftVerticalNavbarState.open ? '110px' : '-16px',
                                            top: leftVerticalNavbarState.open ? '0px' : '-8px',
                                            transition: 'left 0.5s, top 0.5s',
                                            border: `2px solid ${theme.palette.background.default}`,
                                            backgroundColor: theme.palette.primary.main,
                                            color: theme.palette.primary.contrastText,
                                        },
                                    }}
                                    badgeContent={cartLength}
                                />
                            )
                        }
                        label="Handlekurv"
                        path="/handlekurv"
                        buttonPadding={buttonPadding}
                        buttonHeight={buttonHeight}
                        iconSize={buttonIconSize}
                        sx={{
                            mb: 1,
                        }}
                    />
                    <LeftVerticalButton
                        leftVerticalNavbarOpen={leftVerticalNavbarState.open}
                        boldLabel
                        startIcon={<User size={buttonIconSize} />}
                        label="Profil"
                        path="/profil"
                        buttonPadding={buttonPadding}
                        buttonHeight={buttonHeight}
                        iconSize={buttonIconSize}
                    />
                </Stack>
            </Stack>
            <ButtonBase
                sx={{
                    transition: 'all 0.5s',
                    position: 'absolute',
                    top: '26px',
                    borderRadius: '50%',
                    width: '30px',
                    height: '30px',
                    backgroundColor: theme.palette.common.white,
                    border: theme.border.thin,
                }}
                style={{
                    right: leftVerticalNavbarState.open ? '18px' : '-15px',
                    transform: leftVerticalNavbarState.open ? `rotate(180deg)` : `rotate(0deg)`,
                }}
                onClick={() => {
                    if (leftVerticalNavbarState.open) {
                        dispatch(ui.actions.closeGlobalMenu());
                    } else {
                        dispatch(ui.actions.openGlobalMenu());
                    }
                }}
            >
                <ChevronRight size={18} color={theme.palette.grey[700]} />
            </ButtonBase>
        </Stack>
    );
};
